var jQuery = require("jquery")

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

export const rails_ujs = require("@rails/ujs")
rails_ujs.start()
global.Rails = Rails;

require("turbolinks").start()
require("@rails/activestorage").start()

import "bootstrap"
import "../stylesheets/public_website.scss"
